<template>
  <div id="home">
    <!-- <Navbar></Navbar> -->

    <main class=" relative overflow-hidden h-fit pb-4 z-10">
      <div class=" flex relative z-20 items-center overflow-hidden">
        <div class="container mx-auto px-6 flex relative py-16">
          <div
            class="sm:w-2/3 lg:w-4/5 flex flex-col relative z-20 mt-16 fade-in-top min-h-screen"
          >
            <!-- <span class="w-80 md:w-9/12 h-2 bg-gray-100 gradientBg dark:bg-white mb-4 fade-in-left1"></span> -->
            <h4
              class="uppercase text-2xl sm:text-2xl font-medium leading-none dark:text-white text-gray-100 mb-4 animate-text"
              id="header"
            >
              Hi, I'm Toby.
            </h4>
            <h1
              class="title text-6xl sm:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-100"
            >
              <span class="">
                I'll Be Back Soon.
              </span>
            </h1>
            <p
              class="text-2xl sm:text-2xl sm:text-base text-gray-300 dark:text-white mt-3"
            >
              A lot is changing behind the scenes. Thanks for your patience.
            </p>
            <div class="mt-8 fade-in-top hidden">
              <router-link
                to="/start"
                class="hidden md:inline-block title uppercase font-bold py-2 px-4 rounded-lg gradientBg border-0 border-transparent text-white text-md mr-4 hover:bg-blue-800 transition duration-600 ease-in-out"
              >
                Get Started&nbsp;&#8594;
              </router-link>
              <router-link
                to="/start"
                class="md:hidden title uppercase font-bold py-2 px-4 rounded-lg gradientBg border-0 border-transparent text-white text-md mr-4 hover:bg-blue-800 transition duration-600 ease-in-out"
              >
                Get Started&nbsp;&#8594;
              </router-link>
              <div class="pt-10 md:hidden"></div>
              <!-- <router-link
                  to="/work"
                  class="title uppercase font-bold py-2 px-4 md:ml-4 rounded-lg bg-transparent border-2 border-blue-500 text-white dark:text-white hover:bg-blue-800 hover:border-blue-800 hover:text-white text-md transition duration-500 ease-in-out"
                >
                  My Work
                </router-link> -->
            </div>
          </div>
          <div class="sm:block sm:w-1/3 lg:w-3/5 relative"></div>
        </div>
      </div>
    </main>
    <!-- <Promotion></Promotion> -->

    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
  };
</script>

<!-- <script>
    const txts = document.querySelector(".animate-text").children,
      txtsLen = txts.length;
    let index = 0;
    const textInTimer = 5000,
      textOutTimer = 4800;
  
    function animateText() {
      for (let i = 0; i < txtsLen; i++) {
        txts[i].classList.remove("text-in", "text-out");
      }
      txts[index].classList.add("text-in");
  
      setTimeout(function() {
        txts[index].classList.add("text-out");
      }, textOutTimer);
  
      setTimeout(function() {
        if (index == txtsLen - 1) {
          index = 0;
        } else {
          index++;
        }
        animateText();
      }, textInTimer);
    }
    $(document).ready(function() {
      animateText();
    });
  </script> -->

<style>
  .bgNew {
    background-color: #090d16 !important;
  }

  .fade-in-left {
    -webkit-animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .fade-in-left1 {
    -webkit-animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1)
      1.4s both;
    animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) 1.4s both;
  }
  .fade-in-left2 {
    -webkit-animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1)
      0.2s both;
    animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
  }
  .fade-in-bottom {
    -webkit-animation: fade-in-bottom 2s cubic-bezier(0.77, 0, 0.175, 1) 0.1s
      both;
    animation: fade-in-bottom 2s cubic-bezier(0.77, 0, 0.175, 1) 0.1s both;
  }
  .fade-in-top {
    -webkit-animation: fade-in-top 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s
      both;
    animation: fade-in-top 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s both;
  }

  .doink {
    animation: bounce 8s infinite;
  }

  @media (min-width: 768px) {
    .vertical-text {
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
  }

  .diagonal-box {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
    transform: translateY(-150px) skewY(-4deg);
  }

  .diagonal-box2 {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
    transform: translateY(20px) skewY(4deg);
  }

  .diagonal-box3 {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
    transform: translateY(255px) skewY(-2deg);
  }

  .gradientBg {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }

  .content {
    min-width: 50em;
    min-height: 8em;
    max-width: 50em;
    margin: 0 auto;
  }

  .content2 {
    min-width: 50em;
    min-height: 24em;
    max-width: 50em;
    margin: 0 auto;
  }

  .blur {
    filter: blur(4px);
    transition: 0.4s;
    /* content: "Certificate Number: #000-0000-0000-0"; */
  }
  .blur:hover {
    filter: blur(0);
    transition: 0.4s;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    25% {
      transform: translateY(-5%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    75% {
      transform: translateY(5%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
